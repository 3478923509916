.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 51px;
    width: 350px;
    outline: none;
    @media (max-width: 600px) {
        position: relative;
        font-size: 14px;
        letter-spacing: .01rem;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 48px;
        margin-left: 0;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        border-radius: 5px;
        line-height: 25px;
        height: 51px;
        width: auto;
        outline: none;
    }
}